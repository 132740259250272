var ajaxSubmit = require('jquery-form');

jQuery.extend(jQuery.validator.messages, {
    email: "Введите правильный адрес электронной почты.",
    required: "Обязательное поле",
    equalTo: "Пароли не совпадают",
    minlength: jQuery.validator.format("Введите не менее {0} символов"),
    min: jQuery.validator.format("Введите значение больше или равно {0}"),
    number: "Введите число",
    digits: "Вводите только цифры",
});

$("#profile_form").validate({
    submitHandler: function (form) {
        $(form).ajaxSubmit({
            success: function (data) {
                if (data.pk) {
                    new Noty({
                        theme: 'metroui',
                        text: data.message,
                        type: 'success',
                        timeout: '3000',
                        progressBar: true
                    }).show();
                }
            },
            error: function (data) {
                var jsonErrors = data.responseJSON;
                var validator = $("#profile_form").validate();
                validator.showErrors(jsonErrors);
            },
        });
    }
});


$("#change_password_form").validate({
    submitHandler: function (form) {
        $(form).ajaxSubmit({
            success: function (data) {
                $(form)[0].reset();
                new Noty({
                    theme: 'metroui',
                    text: data.message,
                    type: 'success',
                    timeout: '3000',
                    progressBar: true
                }).show();
            },
            error: function (data) {
                var jsonErrors = data.responseJSON;
                var validator = $("#change_password_form").validate();
                validator.showErrors(jsonErrors);
            },
        });
    }
});


$("#requisites_form").validate({
    rules: {
        inn: {
            digits: true
        },
        kpp: {
            digits: true
        },
    },
    submitHandler: function (form) {
        $(form).ajaxSubmit({
            success: function (data) {
                if (data.pk) {

                    if (data.filename) {
                        $(form).find('.filename').text(data.filename);
                    }

                    new Noty({
                        theme: 'metroui',
                        text: data.message,
                        type: 'success',
                        timeout: '3000',
                        progressBar: true
                    }).show();
                }
            },
            error: function (data) {
                var jsonErrors = data.responseJSON;
                var validator = $("#requisites_form").validate();
                validator.showErrors(jsonErrors);
            },
        });
    }
});