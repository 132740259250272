var Cookies = require('js-cookie');

$(function () {
    $(document).ready(function () {
        $("#navbarTogglerDemo02").on("click", "a", function (event) {
            $("#navbarTogglerDemo02").removeClass('show');
        });
    });
    // $(document).ready(function(){
    //     $("#header__menu, #header__menu-mobile, #welcome__arrow, #welcome__text, #footer").on("click","a", function (event) {
    //         event.preventDefault();
    //         var id  = $(this).attr('href'),
    //             top = $(id).offset().top;
    //         $('body,html').animate({scrollTop: top-100}, 1500);
    //     });
    // });
    $(document).ready(function () {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 250) {
                $('#up').fadeIn(900)
            } else {
                $('#up').fadeOut(700)
            }
        });
    });

    $('.list-group-item_category').each(function () {
        $(this).click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
        });
    });

    /*----------------------------------------------------------------------------
    На странице описания исследования показывать и скрывать контент в нижней части
    в зависимости от выбора.
    ----------------------------------------------------------------------------*/
    $(document).ready(function () {
        $('#contents_button').on('click', function (event) {
            $('.ready__type-item').removeClass('ready__type-itemActive');
            $('#contents_button').addClass('ready__type-itemActive');
            $('.readyInner__text').hide();
            $('#contents').show();
        });

        $('#using_methods_button').on('click', function (event) {
            $('.ready__type-item').removeClass('ready__type-itemActive');
            $('#using_methods_button').addClass('ready__type-itemActive');
            $('.readyInner__text').hide();
            $('#using_methods').show();
        });

        $('#data_sources_button').on('click', function (event) {
            $('.ready__type-item').removeClass('ready__type-itemActive');
            $('#data_sources_button').addClass('ready__type-itemActive');
            $('.readyInner__text').hide();
            $('#data_sources').show();
        });
    });

});

// function cartBuy(a, a1, b, b1) {
//     document.getElementById(a).style.display = "flex";
//     document.getElementById(a1).style.display = "flex";
//     document.getElementById(b).style.display = "none";
//     document.getElementById(b1).style.display = "none";
// }

function lkExtend(a) {
    var elem = document.querySelector(a)
    elem.classList.toggle("lkExtendDN");
}

function changeText(a) {
    var id = a.id;
    var text = a.innerText;
    if (text == "Смотреть ещё") {
        a.innerText = "Свернуть";
    }
    if (text == "Свернуть") {
        a.innerText = "Смотреть ещё";
    }
}

$("#register-form").validate({
    rules: {
        password2: {
            equalTo: '#id_password1'
        }
    },
    submitHandler: function (form) {
        $(form).ajaxSubmit({
            success: function (data) {
                if (data.redirect) {
                    window.location.href = data.redirect;
                }
            },
            error: function (data) {
                var jsonErrors = data.responseJSON;
                var validator = $("#register-form").validate();
                validator.showErrors(jsonErrors);
                getReCaptcha();
            },
        });
    }
});

$("#feedback-form").validate({
    errorPlacement: function (error, element) {
        error.appendTo($(element).parent());
    },
    submitHandler: function (form) {
        $(form).ajaxSubmit({
            success: function (data) {
                if (data.pk) {
                    $(form)[0].reset();
                    getReCaptcha();
                    $('#modal-thanx').modal('show');
                }
            },
            error: function (data) {
                var jsonErrors = data.responseJSON;
                var validator = $("#feedback-form").validate();
                validator.showErrors(jsonErrors);
                getReCaptcha();
            },
        });
    }
});

$("#order-form").validate({
    // errorPlacement: function (error, element) {
    //     error.appendTo($(element).parent());
    // },
    submitHandler: function (form) {
        $(form).ajaxSubmit({
            success: function (data) {
                if (data.redirect) {
                    window.location.href = data.redirect;
                }
            },
            error: function (data) {
                var jsonErrors = data.responseJSON;
                var validator = $("#order-form").validate();
                validator.showErrors(jsonErrors);
                //getReCaptcha();
            },
        });
    }
});


var requestSent = false;

$('.js-add-cart').click(function (e) {
    e.preventDefault();
    if (!requestSent) {
        var product_id = $(this).data("id");
        var modal = $('#modal-cart-add');

        $.ajax({
            url: '/cart/add/',
            type: 'POST',
            data: {'product_id': product_id},
            datatype: 'json',
            headers: {'X-CSRFToken': Cookies.get('csrftoken')},
        }).done(function (data) {
            if (data.created) {
                modal.find('.js-cart-name').text(data.product);
                modal.find('.js-cart-price').text(data.price);
                modal.modal('show');
            } else {
                $('#modal-cart-exsist').modal('show');
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.error(jqXHR);
        }).always(function () {
            requestSent = false;
        })
    }
});

$('.js-add-favorite').click(function (e) {
    e.preventDefault();
    let url = $(this).attr("href");
    let modal = $('#modal-favorite-add');
    let pk = $(this).data("id");
    if (!requestSent) {
        $.ajax({
            url: url,
            type: 'POST',
            data: {'pk': pk},
            datatype: 'json',
            headers: {'X-CSRFToken': Cookies.get('csrftoken')},
        }).done(function (data) {
            if (data) {
                modal.find('.js-modal-msg').text(data.msg);
                modal.modal('show');
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.error(jqXHR);
        }).always(function () {
            requestSent = false;
        })
    }
});

$('.js-send-article').click(function (e) {
    e.preventDefault();
    let url = $(this).attr("href");
    let pk = $(this).data("id");
    let modal = $('#modal-article-send');
    if (!requestSent) {
        $.ajax({
            url: url,
            type: 'POST',
            data: {'pk': pk},
            datatype: 'json',
            headers: {'X-CSRFToken': Cookies.get('csrftoken')},
        }).done(function (data) {
            if (data) {
                modal.find('.js-modal-msg').text(data.msg);
                modal.modal('show');
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.error(jqXHR);
        }).always(function () {
            requestSent = false;
        })
    }
});

function ajax_render_cart(type) {
    $.ajax({url: `/cart/?type=${type}`}).done(function (data) {
        if (data) {
            $('.js-cart-wrapper').html(data);
        } else {
            window.location.href = '/';
        }
    });
}

function ajax_render_favorite(type) {
    $.ajax({url: `/lk/favorites/${type}/`}).done(function (data) {
        $('.js-favorite-wrapper').html(data);
    });
}

function ajax_remove_cart(product_id, type) {
    if (!requestSent) {
        $.ajax({
            url: '/cart/remove/',
            type: 'POST',
            data: {'product_id': product_id},
            headers: {'X-CSRFToken': Cookies.get('csrftoken')},
        }).done(function (data) {
            ajax_render_cart(type);
        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.error(jqXHR);
        }).always(function () {
            requestSent = false;
        })
    }
}

function ajax_remove_favorite(url, type) {
    if (!requestSent) {
        $.ajax({
            url: url,
            type: 'GET',
        }).done(function (data) {
            ajax_render_favorite(type);
        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.error(jqXHR);
        }).always(function () {
            requestSent = false;
        })
    }
}

$(document).on("click", '.js-remove-cart', function (e) {
    e.preventDefault();
    var product_id = $(this).data("id");
    var type = $(this).data("type");
    ajax_remove_cart(product_id, type);
});

$(document).on("click", '.js-remove-favorite', function (e) {
    e.preventDefault();
    var url = $(this).attr("href")
    var type = $(this).data("type");
    ajax_remove_favorite(url, type);
});

$('.js-company-fields').hide();

$('.js-type-profile').change(function () {
    console.log(this.value);
    if (this.value === '1') {
        $('.js-company-fields').show();
    } else {
        $('.js-company-fields').hide();
    }
});

$('#id_inn, #id_kpp').on('input change', function () {
    var el = $(this);
    var val = el.val().replace(/[^0-9]/g, '');
    el.val(val);
});
