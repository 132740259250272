import 'bootstrap/dist/css/bootstrap.css'
import 'noty/src/noty.scss'
import 'noty/src/themes/metroui.scss'
import 'jquery-ui/themes/base/theme.css'


import './src/common/css/common.scss'

import "./import-jquery";
import 'jquery-validation'
import 'bootstrap'
import ajaxSubmit from "jquery-form"
window.ajaxSubmit = ajaxSubmit();
window.Noty = require('noty')
import './src/common/js/main'
import './src/common/js/profile'